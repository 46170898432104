import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from './translate.service';

export const TOKEN_KEY = 'token';
// random token van https://jwt.io
export const DUMMY_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // test data
  user = 'test@test.be';
  password = 'pass';


  constructor(
    private ts: TranslateService,
    private transloco: TranslocoService,
    private storageMap: StorageMap,
    private http: HttpClient,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  /**
   * Inloggen van de gebruiker op basis van username & wachtwoord.
   *
   * @param username
   * @param password
   */
  login(username: string, password: string): Observable<{ token: string; }> {
    if (isPlatformServer(this.platformId)) {
      return NEVER;
    }

    return this.http.post<{ token: string }>(`${environment.apiUrl}/authenticate`, { username, password }).pipe(switchMap(token => {
      // token in indexed db opslaan
      return this.storageMap.set(TOKEN_KEY, token.token).pipe(map(() => {
        return token;
      }));
    }),
    catchError(err => {
      return throwError(() => err);
    }));
  }

  refreshToken(token: string): Observable<{ token: string; }> {
    if (isPlatformServer(this.platformId)) {
      return NEVER;
    }

    return this.http.get<{ token: string}>(`${environment.apiUrl}/refreshtoken`, {params: { refreshtoken: token}, headers: { isRefreshToken: 'true' }}).pipe(switchMap(token => {
      // token in indexed db opslaan
      return this.storageMap.set(TOKEN_KEY, token.token).pipe(map(() => {
        return token;
      }));
    }));
  }

  logout(): Observable<undefined> {
    if (isPlatformServer(this.platformId)) {
      return NEVER;
    }

    return this.storageMap.delete(TOKEN_KEY);
  }

  recoverPassword(username: string): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return NEVER;
    }

    const activeLang = this.transloco.getActiveLang();
    const url = this.ts.translate('/gebruikersaccount/wachtwoordResetten', activeLang);
    return this.http.post<boolean>(`${environment.apiUrl}/password-recovery/request`, { username, link: `${window.location.origin}/${activeLang}${url}` });
  }

  resetPassword(password: string, token: string): Observable<{ token: string; }> {
    if (isPlatformServer(this.platformId)) {
      return NEVER;
    }

    return this.http.post<{ token: string }>(`${environment.apiUrl}/password-recovery/reset-password`, { password, token })
    .pipe(switchMap(tokenResp => {
      return this.storageMap.set(TOKEN_KEY, tokenResp.token).pipe(map(() => {
        return tokenResp;
      }));
    }));
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    if (isPlatformServer(this.platformId)) {
      return NEVER;
    }

    return this.http.post(`${environment.apiUrl}/user/changepassword`, {oldPassword, newPassword}, {observe: 'response'}).pipe(catchError(err => {
      return throwError(() => err);
    }));
  }

  // token ophalen doen we door onze indexed db te "watchen". Indien we via de storageMap deze aanpassen zal deze een nieuwe value emitten.
  // Deze emit dus telkens wanneer de waarde veranderd.
  get token$(): Observable<string | undefined> { return isPlatformServer(this.platformId) ? of(undefined) : this.storageMap.watch<string>(TOKEN_KEY, { type: 'string' }); }
  get isLoggedIn$(): Observable<boolean> { return this.token$.pipe(map(token => !!token)); }
}
