import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BreakpointService {

  constructor(
    private breakpointObserver: BreakpointObserver,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  get displayMode$(): Observable<'mobile' | 'tablet' | 'desktop'> {
    if (isPlatformServer(this.platformId)) {
      return of('mobile');
    }

    return combineLatest([
      this.breakpointObserver.observe('(max-width: 600px)').pipe(map(result => result.matches)),
      this.breakpointObserver.observe('(max-width: 1280px)').pipe(map(result => result.matches))
    ]).pipe(map(data => {
      if (data[0]) {
        return 'mobile';
      }
      if (data[1]) {
        return 'tablet';
      }
      return 'desktop';
    }));
  }

  get tinyTablet$(): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }

    return combineLatest([
      this.breakpointObserver.observe('(max-width: 659px)').pipe(map(result => result.matches)),
      this.breakpointObserver.observe('(min-width: 600px)').pipe(map(result => result.matches))
    ]).pipe(map(res => {
      if (res[0] && res[1]) {
        return true;
      }
      return false;
    }));
  }

  get mediumTablet$(): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }

    return combineLatest([
      this.breakpointObserver.observe('(max-width: 841px)').pipe(map(result => result.matches)),
      this.breakpointObserver.observe('(min-width: 600px)').pipe(map(result => result.matches))
    ]).pipe(map(res => {
      if (res[0] && res[1]) {
        return true;
      }
      return false;
    }));
  }
}
