import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetProductOptionsBase } from '../models/get-product-options.model';
import { Page } from '../models/Page.model';
import { Article } from '../models/products.model';

// export interface OnixFilters {
//   imprintNames: string[];
//   publicationYears: number[];
//   priceRange: {
//     min: number;
//     max: number;
//   } | null;
// }

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }


  getPopupProducts(options: GetProductOptionsBase): Observable<Page<Article>> {
    let params = new HttpParams()
      .set('page', options.pageNumber)
      .set('size', options.pageSize);

    if (options.searchByString) {
      params = params.append('search', options.searchByString);
    }

    return this.http.get<Page<Article>>(`${environment.apiUrl}/articles/search`, { params });
  }


  getOneById(id: string): Observable<Article> {
    return this.http.get<Article[]>(`${environment.apiUrl}/articles/${id}`).pipe(map(products => {
      const article: Article = products[0];

      if (article) {
        article.images.forEach(image => {
          image.url = `${environment.apiUrl}${image.url}`;
        });
      }

      return article;
    }));
  }

  getOneByBarcode(barcode: string): Observable<Article | null> {
    const params = new HttpParams().set("barcodeRef", barcode);
    return this.http.get<Page<Article>>(`${environment.apiUrl}/articles/search`, { params }).pipe(map(page => {
      if (page.empty) {
        return null;
      }

      const article: Article = page.content[0];

      if (article) {
        article.images.forEach(image => {
          image.url = `${environment.apiUrl}${image.url}`;
        });
      }

      return article;
    }));
  }
}
