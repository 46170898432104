import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { User } from '../models/user/user.model';


export interface UserState {
  user: User | undefined;
}

export function ceateInitialState(): UserState {
  return {
    user: undefined
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
  constructor() {
    super(ceateInitialState());
  }
}
