import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Country } from '../models/country.model';


export type CountryState = EntityState<Country, number>

export function createInitialState(): CountryState {
  return {};
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'countries', idKey: 'isoCode2' })
export class CountryStore extends EntityStore<CountryState> {
  constructor() {
    super(createInitialState());
  }
}
