import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CleanStringService {

  clean(str: string): string {
    str = str.replace(/[’]/g, '');
    str = str.replace(/["]/g, '');
    str = str.replace(/[']/g, '');
    str = str.replace(/[?]/g, '');
    str = str.replace(/[(]/g, '');
    str = str.replace(/[)]/g, '');
    str = str.replace(/[=]/g, '');
    str = str.replace(/[/]/g, ' ');
    str = str.replace(/[ ]+/g, '-');
    str = str.replace(/[ç]+/g, 'c');
    str = str.replace(/[+]+/g, '');
    str = str.replace(/[&]+/g, '');
    str = str.replace(/[,]+/g, '');
    str = str.replace(/[å]+/g, 'a');
    str = str.replace(/[á]+/g, 'a');
    str = str.replace(/[à]+/g, 'a');
    str = str.replace(/[ä]+/g, 'a');
    str = str.replace(/[â]+/g, 'a');
    str = str.replace(/[é]+/g, 'e');
    str = str.replace(/[è]+/g, 'e');
    str = str.replace(/[ë]+/g, 'e');
    str = str.replace(/[ê]+/g, 'e');
    str = str.replace(/[í]+/g, 'i');
    str = str.replace(/[ì]+/g, 'i');
    str = str.replace(/[ï]+/g, 'i');
    str = str.replace(/[î]+/g, 'i');
    str = str.replace(/[ó]+/g, 'o');
    str = str.replace(/[ò]+/g, 'o');
    str = str.replace(/[ö]+/g, 'o');
    str = str.replace(/[ô]+/g, 'o');
    str = str.replace(/[ú]+/g, 'u');
    str = str.replace(/[ù]+/g, 'u');
    str = str.replace(/[ü]+/g, 'u');
    str = str.replace(/[û]+/g, 'u');
    str = str.replace(/[ç]+/g, 'c');
    str = str.replace(/[œ]+/g, 'oe');
    str = str.replace(/[ñ]+/g, 'n');
    str = str.replace(/[ß]+/g, 'ss');
    str = str.replace(/[Ö]+/g, 'O');
    str = str.replace(/[!]+/g, '');
    str = str.replace(/[*]+/g, '');
    str = str.replace(/[Å]+/g, 'A');
    str = str.replace(/[Î]+/g, 'I');
    str = str.replace(/[´]+/g, '');
    str = str.replace(/[<]+/g, '');
    str = str.replace(/[°]+/g, '');
    str = str.replace(/[É]+/g, 'E');
    str = str.replace(/[æ]+/g, 'ae');
    str = str.replace(/[ø]+/g, 'o');
    str = str.replace(/[Ø]+/g, 'o');
    str = str.replace(/[Á]+/g, 'A');
    str = str.replace(/[–]+/g, '-');
    str = str.replace(/[À]+/g, 'A');
    str = str.replace(/[Š]+/g, 'S');
    str = str.replace(/[š]+/g, 's');
    str = str.replace(/[%]+/g, '');
    str = str.replace(/[Ó]+/g, 'O');
    str = str.replace(/[>]+/g, '');
    str = str.replace(/[Ú]+/g, 'U');
    str = str.replace(/[€]+/g, 'EUR');
    str = str.replace(/[;]+/g, '');
    str = str.replace(/[_]+/g, '-');
    str = str.replace(/[Ï]+/g, 'i');
    str = str.replace(/[Ü]+/g, 'U');
    str = str.replace(/[Ä]+/g, 'A');
    str = str.replace(/[---]+/g, '-');
    str = str.replace(/[--]+/g, '-');
    const ae = String.fromCharCode(230);
    str = str.replaceAll(ae, 'ae');
    return str;
  }
}
