import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { CleanStringService } from '../services/clean-string.service';
import { ProductsService } from '../services/products.service';

@Injectable({
  providedIn: 'root'
})
export class OldUrlGuard implements CanActivate {

    constructor(
        private ps: ProductsService,
        private router: Router,
        private cs: CleanStringService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const id = route.params['id'] as string;
        if (id) {
            return this.ps.getOneById(id).pipe(take(1), map(art => {
                if (art) {
                    return this.router.createUrlTree([`/en/productdetails/${this.cs.clean(art.onix.imprintName)}/${this.cs.clean(art.description)}/${art.barcodeRef}`]);
                }

                return this.router.createUrlTree(['/']);
            }));
        }

        return this.router.createUrlTree(['/']);
    }
}
