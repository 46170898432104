import { isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { Observable } from "rxjs";
import { TranslateService } from "../services/translate.service";

@Injectable({
    providedIn: 'root'
})
export class LanguageGuard implements CanActivateChild {

    constructor(
        private ts: TranslateService,
        private router: Router,
        private transloco: TranslocoService,
        // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const routeLang = childRoute.data['lang'];
        if (isPlatformServer(this.platformId)) {
            if (routeLang) {
                this.transloco.setActiveLang(routeLang);
                this.transloco.setDefaultLang(routeLang);
            }
            return true;
        }

        const urlLang = state.url.split('/')[1];

        if (routeLang && routeLang !== urlLang) {
            const url = '/' + urlLang + this.ts.translate(state.url.slice(3), urlLang);
            return this.router.createUrlTree([ url ]);
        }

        return true;
    }
}
