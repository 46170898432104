import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode, NgModule, PLATFORM_ID } from '@angular/core';
import {
  Translation, translocoConfig, TranslocoLoader, TranslocoModule, TRANSLOCO_CONFIG, TRANSLOCO_LOADER
} from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) { }

  getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`${environment.baseUrl}/assets/i18n/${lang}.json?v=${Date.now()}`);
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'fr', 'nl'],
        defaultLang: 'nl',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
