import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Address } from '../models/user/address.model';


export type AddressState = EntityState<Address, number>

export function createInitialState(): AddressState {
  return {};
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'addresses' })
export class AddressesStore extends EntityStore<AddressState> {
  constructor() {
    super(createInitialState());
  }
}
