import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from '../services/translate.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private ts: TranslateService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  // TODO: log user actions?

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isPlatformServer(this.platformId)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError(error => {
      if (error instanceof HttpErrorResponse) {
        console.error('error with request. message: ', error.message);

        if (request.url === `${environment.apiUrl}/authenticate` &&
          (error.status === 401)) {
          // 401 when authenticating user will not be intercepted --> incorrect login and/or passw
          return throwError(() => error);
        }
        if (request.url === `${environment.apiUrl}/register/user` &&
          (error.status === 409)) {
          // 409 when registering user will not be intercepted --> emailadress already used
          return throwError(() => error);
        }
        if (request.url === `${environment.apiUrl}/user/changepassword`) {
          // errors when changing password are not caught
          return throwError(() => error);
        }
        if (request.url === `${environment.apiUrl}/orders/basket` &&
          (error.status === 406)) {
          // errors when posting basket are not caught
          return throwError(() => error);
        }
        if (error.headers.has('www-authenticate')) {
          // JWT token expitred, passing on the error
          return throwError(() => error);
        }

        if (request.headers.has('skip-error-interceptor')) {
          return throwError(() => error);
        }

        if (error.headers.has('error-message') && error.headers.get('error-message')?.includes('discount code not found')) {
          // 400 when entering wrong discount code will not be intercepted
          return throwError(() => error);
        }

        let errorMsg = '';
        errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        console.error(errorMsg);

        this.router.navigate([this.ts.addLang('/error')], {
          state: {
            errorCode: error.status,
            errorMsg,
            returnUrl: this.router.routerState.snapshot.url
          }
        });

        return throwError(() => errorMsg);
      }

      return throwError(() => error);
    }));
  }
}
