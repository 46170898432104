import { RouteTranslation } from "../models/translations/route-translation.model";

export const ROUTETRANSLATIONS: RouteTranslation[] = [
    {
        en: "home",
        fr: "accueil",
        nl: "welkom"
    },
    {
        en: "login",
        fr: "connexion",
        nl: "aanmelden"
    },
    {
        en: "error",
        fr: "erreur",
        nl: "fout"
    },
    {
        en: "contact",
        fr: "contactezNous",
        nl: "contacteerOns"
    },
    {
        en: "travelGuides",
        fr: "guides",
        nl: "gidsen"
    },
    {
        en: "maps",
        fr: "cartes",
        nl: "kaarten"
    },
    {
        en: "globes",
        fr: "globe",
        nl: "wereldbollen"
    },
    {
        en: "wallmaps",
        fr: "cartesMurales",
        nl: "wandkaarten"
    },
    {
        en: "gifts",
        fr: "cadeaux",
        nl: "geschenken"
    },
    {
        en: "walking",
        fr: "randonner",
        nl: "wandelen"
    },
    {
        en: "new",
        fr: "nouveau",
        nl: "nieuw"
    },
    {
        en: "seasonal",
        fr: "saisonnier",
        nl: "seizoens"
    },
    {
        en: "search",
        fr: "recherche",
        nl: "zoeken"
    },
    {
        en: "blog-en",
        fr: "blog-fr",
        nl: "blog-nl"
    },
    {
        en: "destinations",
        fr: "lieux",
        nl: "bestemmingen"
    },
    {
        en: "products",
        fr: "produits",
        nl: "producten"
    },
    {
        en: "order",
        fr: "commande",
        nl: "bestellen"
    },
    {
        en: "basket",
        fr: "panier",
        nl: "winkelmandje"
    },
    {
        en: "noAccount",
        fr: "sansCompte",
        nl: "geenAccount"
    },
    {
        en: "guest",
        fr: "invite",
        nl: "gast"
    },
    {
        en: "checkout",
        fr: "caisse",
        nl: "afrekenen"
    },
    {
        en: "confirm",
        fr: "confirmation",
        nl: "bevestiging"
    },
    {
        en: "legal",
        fr: "juridique",
        nl: "legaal"
    },
    {
        en: "terms",
        fr: "conditions",
        nl: "voorwaarden"
    },
    {
        en: "privacy-en",
        fr: "privacy-fr",
        nl: "privacy-nl"
    },
    {
        en: "account",
        fr: "compte",
        nl: "gebruikersaccount"
    },
    {
        en: "dashboard",
        fr: "apercu",
        nl: "overzicht"
    },
    {
        en: "details",
        fr: "detail",
        nl: "gegevens"
    },
    {
        en: "orderHistory",
        fr: "historiqueCommandes",
        nl: "bestelGeschiedenis"
    },
    {
        en: "addresses",
        fr: "adresses",
        nl: "adressen"
    },
    {
        en: "change-password",
        fr: "changerMotDePasse",
        nl: "wachtwoord-wijzigen"
    },
    {
        en: "logout",
        fr: "deconnexion",
        nl: "afmelden"
    },
    {
        en: "signup",
        fr: "inscription",
        nl: "registreren"
    },
    {
        en: "recoverPassword",
        fr: "recupererMotDePasse",
        nl: "wachtwoordHerstellen"
    },
    {
        en: "resetPassword",
        fr: "reinitialiserMotDePasse",
        nl: "wachtwoordResetten"
    },
    {
        en: "productdetails",
        fr: "detailsProduit",
        nl: "detailsproduct"
    },
    {
        en: "campingGuidesMaps",
        fr: "camping",
        nl: "kamperen"
    }
];