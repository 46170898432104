import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { Observable } from "rxjs";

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

  constructor(private transloco: TranslocoService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestHeaderLang = request.headers.get('accept-language') ?? '';
    const requestHeaderLang2 = request.headers.get('content-language') ?? '';
    request = request.clone({
      setHeaders: {
        'accept-language': `${this.transloco.getActiveLang()},${requestHeaderLang}`,
        'content-language': `${this.transloco.getActiveLang()},${requestHeaderLang2}`
      }
    });

    return next.handle(request);
  }
}
