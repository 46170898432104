import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.token$.pipe(take(1), map(token => {
      if (!token) {
        return this.router.createUrlTree(['/', 'login']);
      }

      const parsed: { isAdmin: boolean } = jwt_decode(token);
      if (!parsed.isAdmin) {
        return this.router.createUrlTree(['/', 'login']);
      }

      return parsed.isAdmin;
    }));
  }

}
