import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.token$.pipe(
      take(1),
      switchMap(token => {
        if (token &&
            req.url.startsWith(environment.apiUrl)) {
          req = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
          });
        }
        return next.handle(req).pipe(
          catchError(res => {
            if (res instanceof HttpErrorResponse && res.status === 401) { // http-error-interceptor??
              return this.handleUnauthorized(req, next, res);
            } else {
              return throwError(() => res);
            }
          })
        );
      }));
  }

  handleUnauthorized(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<any> {
    if (!error.headers.has('www-authenticate')) {
        // no token!
        return throwError(() => error);
    }
    if (!error.headers.get('www-authenticate')!.includes('Jwt expired')) { // TODO: test in combo with http error interceptor
      // token too old, can not be refreshed
        return this.authService.logout().pipe(concatMap(() => throwError(() => error)));
    }
    // retrieve old token and refresh
    return this.authService.token$.pipe(
      take(1),
      switchMap(token => {
        return this.authService.refreshToken(token!).pipe(concatMap(newAccessToken => {
          request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${newAccessToken.token}`)
              });
              return next.handle(request);
          }));
      })
    );

  }
}
