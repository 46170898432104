import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { AppBrowserModule } from './app/app.browser.module';

Sentry.init({
  dsn: 'https://277e571fe8564c7cab038b42d428ed0b@o4504871427637248.ingest.sentry.io/4504909687095296',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [environment.baseUrl, environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay()
  ],

  environment: environment.sentry.env_name,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  enabled: environment.sentry.enabled
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppBrowserModule)
  .catch(err => console.error(err));
