export const NON_PRIOR_WHEIGHT_LIMIT = 0.330;

export const LANGUAGE_COOKIE_KEY = 'user_language_preference';

export const GOOGLE_ANALYTICS_ID = 'G-LXKBEJFL71'; // also edit index.html

export const PAGE_SIZE_DEFAULT = 20;

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const DEFAULT_IMAGE_URL = '/assets/images/not-available/loading_img.webp';

// export const COUNTRIES = [
//   'België',
//   'Oostenrijk',
//   'Bulgarije',
//   'Kroatië',
//   'Cyprus',
//   'Tsjechië',
//   'Denemarken',
//   'Estland',
//   'Finland',
//   'Frankrijk',
//   'Duitsland',
//   'Gibraltar',
//   'Griekenland',
//   'Hongarije',
//   'IJsland',
//   'Ierland',
//   'Italië',
//   'Letland',
//   'Litouwen',
//   'Luxemburg',
//   'Malta',
//   'Monaco',
//   'Nederland',
//   'Noorwegen',
//   'Polen',
//   'Portugal',
//   'Slowakije',
//   'Slovenië',
//   'Spanje',
//   'Zweden',
//   'Zwitserland',
//   'Turkije',
//   'Verenigd Koninkrijk'
// ];
