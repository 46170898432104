import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ROUTETRANSLATIONS } from '../constants/route-translations';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
    translations = ROUTETRANSLATIONS;

    constructor(private transloco: TranslocoService) { }

    translate(route: string, lang: string): string {
        const segments: string[] = route.slice(1).split('/');
        const translated: string[] = [];
        let url = '';

        if (segments[0] === 'productdetails' ||
            segments[0] === 'detailsProduit' ||
            segments[0] === 'detailsproduct')
        {
            const trans = this.translations.find(x => x.en === segments[0] || x.fr === segments[0] || x.nl === segments[0]);
            if (trans) {
                if (lang === 'fr') {
                    url = '/' + trans.fr;
                } else if (lang === 'en') {
                    url = '/' + trans.en;
                } else {
                    url = '/' + trans.nl;
                }
            } else {
                url = '/' + segments[0];
            }
            for (let i = 1; i < segments.length; i++) {

                url += '/' + segments[i];
            }
            return url;
        }

        for (const seg of segments) {
            const trans = this.translations.find(x => x.en === seg || x.fr === seg || x.nl === seg);
            if (trans) {
                if (lang === 'fr') {
                    translated.push(trans.fr);
                } else if (lang === 'en') {
                    translated.push(trans.en);
                } else {
                    translated.push(trans.nl);
                }
            }
        }

        for (const seg of translated) {
            url += '/' + seg;
        }
        return url;
    }

    localise(route: string, lang: string): string {
        return '/' + lang + this.translate(route, lang);
    }

    addLang(route: string): string {
        return '/' + this.transloco.getActiveLang() + route;
    }
}
