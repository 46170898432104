import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private previousUrl = new BehaviorSubject<string>('/home');
  public previousUrl$ = this.previousUrl.asObservable();

  setPreviousUrl(previousUrl: string): void {
    this.previousUrl.next(previousUrl);
  }
}
