import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoService } from '@ngneat/transloco';
import { StorageMap } from '@ngx-pwa/local-storage';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { getWindow } from 'ssr-window';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initLanguage } from './language.initializer';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { LocaleInterceptor } from './shared/interceptors/locale-interceptor';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { BreakpointService } from './shared/services/breakpoint.service';
import { TranslocoRootModule } from './transloco-root.module';
import { Router } from '@angular/router';
import { TranslateService } from './shared/services/translate.service';


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: getWindow().location.host,
    // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#f5dd56',
      border: 'transparent'
    },
  },
  theme: 'edgeless',
  type: 'info',
  content: {
    message:
      'Deze website gebruikt cookies om je de best mogelijke ervaring te garanderen.',
    dismiss: 'Begrepen!',
    link: 'Meer weten',
    href: `${getWindow().location.origin}/en/legal/privacy-en`,
    target: '_self'

  },
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatDialogModule
  ],
  providers: [
    CookieService,
    BreakpointService,
    {
      provide: APP_INITIALIZER,
      useFactory: initLanguage,
      deps: [StorageMap, TranslocoService, PLATFORM_ID, Router, TranslateService],
      multi: true
    },
    {provide: MAT_DATE_LOCALE, useValue: 'nl-BE'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
