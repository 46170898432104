import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './shared/guards/admin.guard';
import { LanguageGuard } from './shared/guards/language.guard';
import { OldUrlGuard } from './shared/guards/old-url.guard';
import { RedirectComponent } from './webshop/redirect/redirect.component';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [ AdminGuard ]
  },
  {
    path: 'guides/productDetails/:id/:imprint/:title',
    canActivate: [OldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'maps/productDetails/:id/:imprint/:title',
    canActivate: [OldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'globes/productDetails/:id/:imprint/:title',
    canActivate: [OldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'wallmaps/productDetails/:id/:imprint/:title',
    canActivate: [OldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'gifts/productDetails/:id/:imprint/:title',
    canActivate: [OldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'search/productDetails/:id/:imprint/:title',
    canActivate: [OldUrlGuard],
    component: RedirectComponent
  },
  {
    path: '',
    redirectTo: 'nl',
    pathMatch: 'full'
  },
  // {
  //   path: '**',
  //   redirectTo: 'nl/welkom',
  //   pathMatch: 'full'
  // },
  {
    path: 'nl',
    loadChildren: () => import('./webshop/home/home.module').then(m => m.HomeModule),
    canActivateChild: [LanguageGuard],
    data: { lang: 'nl' }
  },
  {
    path: 'fr',
    loadChildren: () => import('./webshop/home/home.module').then(m => m.HomeModule),
    canActivateChild: [LanguageGuard],
    data: { lang: 'fr' }
  },
  {
    path: 'en',
    loadChildren: () => import('./webshop/home/home.module').then(m => m.HomeModule),
    canActivateChild: [LanguageGuard],
    data: { lang: 'en' }
  },

  {
    path: '**',
    redirectTo: 'nl/welkom',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    enableTracing: false
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
