import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit{

  constructor(
    private title: Title,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.pathFromRoot[1].url[4].path && this.route.snapshot.pathFromRoot[1].url[4].path !== '') {
        this.title.setTitle(this.route.snapshot.pathFromRoot[1].url[4].path + ' - redirect');
    }
  }

}
